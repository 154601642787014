.no-content label::after {
  background: tomato;
  content: "~";
}

.site-card-border-less-wrapper {
  padding: 30px;
}

.ant-picker {
  padding: 15px;
  width: 100%;
}

.layout-content {
  padding: 10px 20px;
}

/* table */
.fixed_headers {
  /* width: 750px; */
  table-layout: fixed;
  border-collapse: collapse;
}
.fixed_headers th {
  text-decoration: underline;
}
.fixed_headers th,
.fixed_headers td {
  padding: 5px;
  text-align: left;
}
.fixed_headers td:nth-child(1),
.fixed_headers th:nth-child(1) {
  min-width: 200px;
}
.fixed_headers td:nth-child(2),
.fixed_headers th:nth-child(2) {
  min-width: 200px;
}
.fixed_headers td:nth-child(3),
.fixed_headers th:nth-child(3) {
  width: 350px;
}
.fixed_headers thead {
  background-color: #333;
  color: #fdfdfd;
}
.fixed_headers thead tr {
  display: block;
  position: relative;
}
.fixed_headers tbody {
  display: block;
  overflow: auto;
  width: 100%;
  /* height: 300px; */
}
.fixed_headers tbody tr:nth-child(even) {
  background-color: #ddd;
}
.old_ie_wrapper {
  /* height: 300px; */
  width: 750px;
  overflow-x: hidden;
  overflow-y: auto;
}
.old_ie_wrapper tbody {
  height: auto;
}

/* .custom-row-hover:hover tbody tr {background: rgb(170, 255, 198)!important;} */
.ant-table-cell-row-hover {
  background: rgb(172, 172, 172) !important;
}

.table-transactions tr:nth-child(even) {
  background: #dcdcdc;
}
.table-transactions tr:nth-child(odd) {
  background: #fff;
}
